/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { logoDynomo, logomentor } from './components/icons/icon';
import { CircularProgress } from '@mui/material';
const App = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={
    <main className='h-screen flex flex-col justify-center items-center'>
      <CircularProgress className='text_primarylight' size={50} thickness={2} />
      <div className=" bg_primarylight absolute rounded-full p-2">
        <img src={logomentor} className='w-5 h-5 object-contain' alt="" />
      </div>
    </main>
  }>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Suspense>
);
reportWebVitals();
